exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-old-tsx": () => import("./../../../src/pages/blog-old.tsx" /* webpackChunkName: "component---src-pages-blog-old-tsx" */),
  "component---src-pages-divulgacao-do-relatorio-de-transparencia-salarial-tsx": () => import("./../../../src/pages/divulgacao-do-relatorio-de-transparencia-salarial.tsx" /* webpackChunkName: "component---src-pages-divulgacao-do-relatorio-de-transparencia-salarial-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-nossa-historia-tsx": () => import("./../../../src/pages/nossa-historia.tsx" /* webpackChunkName: "component---src-pages-nossa-historia-tsx" */),
  "component---src-pages-nossas-linhas-old-tsx": () => import("./../../../src/pages/nossas-linhas-old.tsx" /* webpackChunkName: "component---src-pages-nossas-linhas-old-tsx" */),
  "component---src-pages-onde-comprar-tsx": () => import("./../../../src/pages/onde-comprar.tsx" /* webpackChunkName: "component---src-pages-onde-comprar-tsx" */),
  "component---src-pages-politicasdeprivacidade-tsx": () => import("./../../../src/pages/politicasdeprivacidade.tsx" /* webpackChunkName: "component---src-pages-politicasdeprivacidade-tsx" */),
  "component---src-pages-receitas-old-tsx": () => import("./../../../src/pages/receitas-old.tsx" /* webpackChunkName: "component---src-pages-receitas-old-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-nossas-linhas-tsx": () => import("./../../../src/templates/nossas-linhas.tsx" /* webpackChunkName: "component---src-templates-nossas-linhas-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-produto-tsx": () => import("./../../../src/templates/produto.tsx" /* webpackChunkName: "component---src-templates-produto-tsx" */),
  "component---src-templates-receita-tsx": () => import("./../../../src/templates/receita.tsx" /* webpackChunkName: "component---src-templates-receita-tsx" */),
  "component---src-templates-receitas-tsx": () => import("./../../../src/templates/receitas.tsx" /* webpackChunkName: "component---src-templates-receitas-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */)
}

